import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Image from 'next/legacy/image';
import { useTranslation } from 'i18n';

interface SearchHeaderFieldProps {
  inputValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface StyleIprops {
  language: string;
}

const useTextFieldStyles = makeStyles<StyleIprops>()((theme, params) => ({
  root: {
    '&&&': {
      height: 32,
      width: 375,
      '@media (max-width: 1076px)': {
        width: 288,
      },
      ...(params?.language === 'fr' && {
        '@media (max-width: 1123px)': {
          width: 288,
        },
        '@media (max-width: 1036px)': {
          width: 218,
        },
      }),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      overflow: 'hidden',
      borderRadius: 100,
      paddingRight: 30,
      fontSize: 13,
      fontWeight: 500,
    },
    input: {
      padding: '4px 0 5px',
    },
  },
  notchedOutline: {
    borderWidth: '1.5px',
    borderColor: `${theme.palette.primary.main} !important`,
  },
}));

const SearchHeaderField: React.FC<React.PropsWithChildren<SearchHeaderFieldProps>> = (props) => {
  const { inputValue, onChange } = props;
  const [t, i18n] = useTranslation();
  const { language } = i18n;
  const { classes: textFieldClasses } = useTextFieldStyles({ language });
  const [searchTerm, setSearchTerm] = useState(inputValue);
  const [inputTimeout, setInputTimeout] = useState(null);

  useEffect(() => () => clearTimeout(inputTimeout as NodeJS.Timeout), [inputTimeout]);
  useEffect(() => setSearchTerm(inputValue), [inputValue]);

  const handleSetInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { value } = event.target;
    setSearchTerm(value);
    if (inputTimeout) clearTimeout(inputTimeout as NodeJS.Timeout);
    setInputTimeout(
      setTimeout(() => {
        onChange(event);
      }, 500),
    );
  };

  return (
    <TextField
      fullWidth
      name="search"
      variant="outlined"
      placeholder={t<string>('public:searchHeader.placeholder')}
      value={searchTerm}
      onChange={handleSetInputValue}
      InputProps={{
        classes: textFieldClasses,
        startAdornment: (
          <InputAdornment position="start">
            <Image src="/static/img/icon-search.png" alt="icon-search" width={17} height={17} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchHeaderField;
